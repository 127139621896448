import OrganicCardHelp from '~/components/Molecules/Organic/CardHelp/CardHelp.vue'
import Accordion from '~/components/Atoms/Organic/Accordion/Accordion.vue'
import AccordionItemCustom from '~/components/Atoms/Organic/AccordionItemCustom/AccordionItemCustom'
import modalSignup from '~/mixins/modalSignup.js'
import device from '~/mixins/device.js'

export default {
  mixins: [modalSignup, device],

  props: {
    titles: {
      type: Object,
      required: true,
    },

    cards: {
      type: Array,
      required: true,
    },

    cta: {
      type: Object,
    },
  },

  components: {
    OrganicCardHelp,
    Accordion,
    AccordionItemCustom,
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  data() {
    return {
      OrganicCardHelp: OrganicCardHelp,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 45,
        resistanceRatio: 0,
        breakpoints: {
          768: {
            spaceBetween: 45,
            slidesPerView: 2,
          },
          425: {
            spaceBetween: 45,
            slidesPerView: 1,
          },
        },
      },
    }
  },

  computed: {
    hasCta() {
      return Boolean(this.cta)
    },
  },
}
